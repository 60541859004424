import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
    name: "token",
    initialState: {
        value: null,
    },
    reducers: {
        setToken: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setToken } = tokenSlice.actions;
export const tokenReducer = tokenSlice.reducer;

const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        value: {
            wallet: 0,
            withdrawWallet: 0,
        },
    },
    reducers: {
        setWallet: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setWallet } = walletSlice.actions;
export const walletReducer = walletSlice.reducer;

const userSlice = createSlice({
    name: "user",
    initialState: {
        value: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
