const Clickable = ({
    children,
    className,
    onClick = () => {},
    disabled,
    style,
}) => {
    return (
        <div
            onClick={!disabled ? onClick : () => {}}
            className={className}
            style={{ opacity: disabled ? 0.5 : 1, cursor: "pointer", ...style }}
        >
            {children}
        </div>
    );
};

export default Clickable;
