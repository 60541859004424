import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

import Login from "../pages/login";
import AddAmount from "../pages/addAmount";
import Withdraw from "../pages/withdraw";
import WalletHistory from "../pages/walletHistory";
import Home from "../pages/home";

import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../functions/api";
import Land from "../pages/land";
import NotFound from "../pages/notFound";
import { io } from "socket.io-client";
import getWallet from "../functions/getWallet";
import SastaAdmin from "../pages/sastaAdmin";
import Telegram from "../pages/telegram";
import Reffer from "../pages/reffer";
import { setUser } from "../redux/slices";

export const pixelID = "1182904892874739";

const Navigation = () => {
  const dispatch = useDispatch();

  const token = useSelector((s) => s.token.value);

  const getUser = async () => {
    try {
      const data = await api.get("/user");
      dispatch(setUser(data));
    } catch (err) {
      console.log("🚀 ~ getUser ~ err:", err);
    }
  };

  useEffect(() => {
    if (token) {
      getWallet(token, dispatch);
      getUser();
      const socket = io(api.baseURL);
      socket.on("result", getWallet);
      return () => socket.off("result");
    }
  }, [token]);

  useEffect(() => {
    ReactPixel.init(pixelID);
    ReactPixel.pageView();

    ReactGA.initialize([
      {
        trackingId: "G-ZDYPPJRJ4X",
      },
    ]);
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer autoClose={1500} />
      <div>
        <main>
          <Routes>
            <Route path="/" element={<Land />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/game" element={<Home />} />
            <Route path="/add-amount" element={<AddAmount />} />
            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="/wallet-history" element={<WalletHistory />} />
            <Route path="/sasta-admin/:market" element={<SastaAdmin />} />
            <Route path="/telegram" element={<Telegram />} />
            <Route path="/refer" element={<Reffer />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default Navigation;
