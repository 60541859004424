import { useEffect, useState } from "react";
import Clickable from "../components/clickable";
import images from "../constants/images";
import OTPInput from "react-otp-input";
import api from "../functions/api";
import { useNavigate } from "react-router-dom";
import toast from "../functions/toast";
import { setToken } from "../redux/slices";
import { useDispatch } from "react-redux";
import { pwaInstallHandler } from "pwa-install-handler";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const Login = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [mobNo, setMobNo] = useState(null);
  const [otp, setOtp] = useState("1234");
  const [refCode, setRefCode] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const sendOTP = async () => {
    try {
      await api.post("/login", {
        mobNo,
      });
      toast.success("OTP sent to " + mobNo);
      setOtpSent(true);
    } catch (err) {
      console.log("🚀 ~ sendOTP ~ err:", err);
    }
  };

  const verifyOTP = async (otp = "1234") => {
    try {
      if (!otp || !otp.match(/\b\d+\b/)) return;

      setOtp(otp);

      if (otp.split("").length === 4) {
        const { token } = await api.post("/verify-otp", {
          mobNo,
          otp,
          refCode,
        });
        dispatch(setToken(token));
        ReactPixel.trackCustom("Login", { mobNo });
        ReactGA.event({
          category: "MainEngagement",
          action: "Login",
        });
        if (pwaInstallHandler.canInstall()) pwaInstallHandler.install();
        navigate("/");
      }
    } catch (err) {
      console.log("🚀 ~ verifyOTP ~ err:", err);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setRefCode(urlParams.get("c"));
  }, []);

  useEffect(() => {
    if (otpSent) {
      setTimeout(verifyOTP, 1500);
    }
  }, [otpSent]);

  return (
    <div className="Login">
      <div className="container">
        <div className="logo-icon">
          <img src={images.logo} className="logo" />
        </div>
        <div className="title">Login</div>
        <div className="text">Please login with your mobile number & OTP</div>
      </div>

      <div className="mobile">
        <div className="input-container">
          <div className="mobile-num">
            <img src={images.mobile} className="mobile-icon" />
          </div>
          <div>
            <input
              className="input"
              placeholder="Enter Your Mobile Number"
              maxLength={10}
              onChange={(e) => setMobNo(e.currentTarget.value)}
              inputMode="numeric"
            />
          </div>
        </div>
        <div className="user-ref">
          <input
            className="refer"
            placeholder="Refer Code"
            onChange={(e) => setRefCode(e.currentTarget.value)}
            value={refCode}
          />
        </div>
        <Clickable
          className="btn"
          onClick={sendOTP}
          disabled={!mobNo || !mobNo.match(/^[6-9]\d{9}$/)}
        >
          Send OTP
        </Clickable>

        {otpSent && (
          <OTPInput
            value={otp}
            onChange={verifyOTP}
            numInputs={4}
            renderInput={(props) => (
              <input {...props} className="otp-input" inputMode="numeric" />
            )}
            shouldAutoFocus
          />
        )}
      </div>
    </div>
  );
};

export default Login;
