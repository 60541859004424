import { useEffect, useState } from "react";
import api from "../functions/api";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const WalletHistory = () => {
  const location = useLocation();

  const q = new URLSearchParams(location.search);

  const [tabIndex, setTabIndex] = useState(q.get("tab") || 0);
  const [data, setData] = useState([]);

  const { wallet } = useSelector((s) => s.wallet.value);

  const navigate = useNavigate();

  const token = useSelector((s) => s.token.value);

  const getRecharges = async () => {
    try {
      const data = await api.get("/recharge-history");
      setData(data);
    } catch (err) {
      console.log("🚀 ~ History ~ err:", err);
    }
  };

  const getWithdraws = async () => {
    try {
      const data = await api.get("/withdraw-history");
      setData(data);
    } catch (err) {
      console.log("🚀 ~ getWithdraws ~ err:", err);
    }
  };

  useEffect(() => {
    setData([]);
    if (token) {
      tabIndex == 0 ? getRecharges() : getWithdraws();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (!token) return navigate("/login");
  }, [token]);

  return (
    <div className="WalletHistory">
      <div className="container">
        <div>
          <div className="title">Wallet History</div>
          <div className="balance">
            <div className="wallet-text">My Balance</div>
            <div className="wallet-amount">₹ {wallet.toFixed(2)} INR</div>
          </div>
        </div>

        <div className="tab-bar">
          <div
            className={tabIndex == 0 ? "indexActive tabName" : "tabName"}
            onClick={() => setTabIndex(0)}
          >
            Recharge
          </div>
          <div
            className={tabIndex == 1 ? "indexActive tabName" : "tabName"}
            onClick={() => setTabIndex(1)}
          >
            Withdrawal
          </div>
        </div>

        {data.map((item, index) => (
          <div className="withdrawal-status" key={index}>
            <div>
              <div className="recharge-ruppes">
                {tabIndex == 0 ? "Recharge" : "Withdrawal"} ₹{item.amount}
              </div>
              <div className="status-process">
                <div className="status">Status: </div>
                <div
                  className={`process ${item.status == 1 ? "green" : "red"}`}
                >
                  {item.status == 0
                    ? "In Process"
                    : item.status == 1
                    ? "Success"
                    : "Fail"}
                </div>
              </div>
            </div>
            <div className="recharge-status">
              <div className="date-time">
                Time: {moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletHistory;
