import { useEffect, useState } from "react";
import Clickable from "../components/clickable";
import images from "../constants/images";
import api from "../functions/api";
import toast from "../functions/toast";
import { useNavigate } from "react-router-dom";
import copy from "clipboard-copy";
import { useDispatch, useSelector } from "react-redux";
import getWallet from "../functions/getWallet";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const AddAmount = () => {
  const [amount, setAmount] = useState(null);
  const [transRef, setTransRef] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const token = useSelector((s) => s.token.value);

  const Amount = async () => {
    if (parseFloat(amount) < 100)
      return toast.error("Minimum recharge amount is Rs. 100");

    try {
      await api.post("/add-recharge", { amount, type: 1, transRef });
      getWallet(token, dispatch);
      ReactPixel.trackCustom("Recharge", amount);
      ReactGA.event({
        category: "MainEngagement",
        action: "Recharge",
        value: amount,
      });
      toast.success("Recharge request sumbmitted");
      navigate("/wallet-history?tab=0");
    } catch (err) {
      console.log("🚀 ~ Amount ~ err:", err);
    }
  };

  const upiId = "winoxtech-1@okaxis";

  const copyToClipboard = async () => {
    try {
      copy(upiId);
      toast.success("UPI ID copied to clipboard");
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  useEffect(() => {
    if (!token) return navigate("/login");
  }, [token]);

  return (
    <div className="AddAmount">
      <div className="container">
        <div>
          <div className="title">Add Amount</div>
          <img src={images.qr} className="qr-img" />
        </div>

        <div className="upi-id">UPI Payment ID: {upiId}</div>

        <Clickable className="copy" onClick={copyToClipboard}>
          COPY UPI ID
        </Clickable>

        <div className="text">
          Copy UPI ID or Scane QR Code & sand recharge amount, after send amount
          submit your recharge details below
        </div>

        <input
          className="input"
          placeholder="Enter Minimum Amount Rs. 100"
          onChange={(e) => setAmount(e.currentTarget.value)}
          min="300"
          value={amount}
          inputMode="numeric"
        />
        <input
          className="input"
          placeholder="Enter UPI Ref Number"
          onChange={(e) => setTransRef(e.currentTarget.value)}
          value={transRef}
        />

        <Clickable
          className="btn"
          onClick={() => {
            Amount();
          }}
          disabled={!amount || !amount.match(/^\d*\.?\d+$/)}
        >
          Submit Recharge
        </Clickable>
      </div>
    </div>
  );
};

export default AddAmount;
