import { setWallet } from "../redux/slices";
import api from "./api";

const getWallet = async (token, dispatch) => {
    if (!token) return;
    try {
        const data = await api.get("/wallet");
        dispatch(setWallet(data));
    } catch (err) {
        console.log("🚀 ~ getWal ~ err:", err);
    }
};

export default getWallet;
