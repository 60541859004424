import { useEffect, useState } from "react";
import Clickable from "../components/clickable";
import api from "../functions/api";
import toast from "../functions/toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getWallet from "../functions/getWallet";
import ReactGA from "react-ga4";

const Withdraw = () => {
  const [upiID, setUpiId] = useState(null);
  const [amount, setAmount] = useState(null);

  const { withdrawWallet } = useSelector((s) => s.wallet.value);

  const prices = [500, 1000, 1500, 2000, 2500, 3000];

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const token = useSelector((s) => s.token.value);

  const withdraw = async () => {
    if (parseFloat(amount) < 130)
      return toast.error("Minimum withdraw amount is Rs. 130");
    try {
      await api.post("/add-withdraw", { amount, upiID });
      getWallet(token, dispatch);
      toast.success("Withdrawal request submitted");
      ReactGA.event({
        category: "MainEngagement",
        action: "Withdraw",
        value: amount,
      });
      navigate("/wallet-history?tab=1");
    } catch (err) {
      console.log("🚀 ~ withdraw ~ err:", err);
    }
  };

  useEffect(() => {
    if (!token) return navigate("/login");
  }, [token]);

  return (
    <div className="Withdraw">
      <div className="container">
        <div>
          <div className="title">Withdraw</div>
          <div className="balance">
            <div className="wallet-text">Winning Amount</div>
            <div className="wallet-amount">
              ₹ {withdrawWallet.toFixed(2)} INR
            </div>
          </div>
          <input
            className="input"
            placeholder="Enter Withdrawal Amount Minimum Rs.130"
            onChange={(e) => setAmount(e.currentTarget.value)}
            value={amount}
            min="500"
            inputMode="numeric"
          />

          <div className="tab">
            {prices.map((item, index) => (
              <div
                className="tab-amount"
                onClick={() => setAmount(item)}
                key={index}
              >
                <div className="amount">{item}</div>
              </div>
            ))}
          </div>

          <input
            className="input"
            placeholder="Enter UPI ID"
            onChange={(e) => setUpiId(e.currentTarget.value)}
          />
        </div>

        <Clickable
          className="btn"
          onClick={() => {
            withdraw();
          }}
          disabled={
            !amount || !upiID || !amount.toString().match(/^\d*\.?\d+$/)
          }
        >
          Submit Recharge
        </Clickable>
      </div>
    </div>
  );
};

export default Withdraw;
