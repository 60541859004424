const images = {
    mobile: require("../assets/images/mobile.png"),
    qr: require("../assets/images/qr.png"),
    logo: require("../assets/images/logo.png"),
    trophy: require("../assets/images/trophy.png"),
    menu: require("../assets/images/menu.png"),
    wallet: require("../assets/images/wallet.png"),
    withdraw: require("../assets/images/withdraw.png"),
    addFund: require("../assets/images/add-fund.png"),
    history: require("../assets/images/history.png"),
    game: require("../assets/images/game.png"),
    cancel: require("../assets/images/cancel.png"),
    install: require("../assets/images/install.png"),
    support: require("../assets/images/support.png"),
    channel: require("../assets/images/channel.png"),
    refer: require("../assets/images/refer.png"),
    user: require("../assets/images/user.png"),
    giftSharp: require("../assets/images/gift-sharp.png"),
};

export default images;
