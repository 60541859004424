import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Land = () => {
  const navigate = useNavigate();

  const token = useSelector((s) => s.token.value);

  useEffect(() => {
    // navigate(token ? "/game" : "/login", { replace: true });
    navigate("/game", { replace: true });
  }, [token]);

  return <></>;
};

export default Land;
