import Clickable from "../components/clickable";
import images from "../constants/images";
import copy from "clipboard-copy";
import toast from "../functions/toast";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../functions/api";
import moment from "moment";

const Reffer = () => {
  const [data, setData] = useState(null);

  const user = useSelector((s) => s.user.value);

  const navigate = useNavigate();

  const upiId = "https://www.game555.live/login?c=" + user.refCode;

  const copyToClipboard = async () => {
    try {
      copy(upiId);
      toast.success("Ref code copied to clipboard");
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const getData = async () => {
    try {
      const data = await api.get("/ref-history");
      setData(data);
    } catch (err) {
      console.log("🚀 ~ getData ~ err:", err);
    }
  };

  const maskNumber = (number, visibleDigits = 3, maskChar = "*") => {
    let numStr = number.toString();
    let visibleFromStart = Math.min(visibleDigits, numStr.length);
    let visibleFromEnd = Math.min(
      visibleDigits,
      numStr.length - visibleFromStart
    );
    let maskedStr =
      numStr.substring(0, visibleFromStart) +
      maskChar.repeat(numStr.length - visibleFromStart - visibleFromEnd) +
      numStr.substring(numStr.length - visibleFromEnd);

    return maskedStr;
  };

  useEffect(() => {
    if (!user) navigate("/login");
    else getData();
  }, []);

  return (
    <div className="Reffer">
      <div className="container">
        <div className="title">Refer & Earn</div>
        {data && (
          <div className="bonus">
            <div>
              <div className="total-bonus">My Total Bonus</div>
              <div className="ruppes">Rs. {data.total}/-</div>
            </div>
            <div>
              <img src={images.giftSharp} className="gift" />
            </div>
          </div>
        )}

        {data && (
          <div>
            <div className="total-user">Total Members - {data.totalUsers}</div>
          </div>
        )}

        <div className="reffer">
          <Clickable className="code">
            My Refer Link
            <div className="link">{upiId}</div>
          </Clickable>
        </div>
        <div className="copy-share">
          <Clickable className="copy" onClick={copyToClipboard}>
            Copy
          </Clickable>
          {navigator.share && (
            <Clickable
              className="copy"
              onClick={() => navigator.share({ url: upiId })}
            >
              share
            </Clickable>
          )}
        </div>
      </div>

      <div className="commision">
        <div className="text">YOUR RECHARGE COMMISION RECORDS</div>
      </div>

      <div className="user-records">
        {data &&
          data.list.map((item) => (
            <div className="records">
              <div className="time">
                {moment(item.createdAt).format("hh:mm A DD/MM/YYYY")}
              </div>
              <div className="mobile-num">{maskNumber(item.user.mobNo)}</div>
              <Clickable className="recharge">{item.amount}+</Clickable>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Reffer;
