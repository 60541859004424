import axios from "axios";
import toast from "./toast";
import { store } from "../redux/store";

const baseURL = "https://apis.game555.live";
// const baseURL = "http://localhost:8000";

const api = {
  post: async (path, data, isMUltipart) =>
    new Promise(async (resolve, reject) => {
      try {
        const token = store.getState().token.value;

        const { data: res } = await axios({
          method: "post",
          url: baseURL + path,
          data,
          headers: {
            "Content-Type": isMUltipart
              ? "multipart/form-data"
              : "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (res.status === "SUCCESS") {
          resolve(res.data);
        } else {
          toast.error(res.msg || "Something went wrong");
          reject(res.msg || "Something went wrong");
        }
      } catch (err) {
        reject(err);
        return toast.error("Something went wrong");
      }
    }),
  get: async (path) =>
    new Promise(async (resolve, reject) => {
      try {
        const token = store.getState().token.value;

        const { data: res } = await axios({
          method: "get",
          url: baseURL + path,
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (res.status === "SUCCESS") {
          resolve(res.data);
        } else {
          toast.error(res.msg || "Something went wrong");
          reject(res.msg || "Something went wrong");
        }
      } catch (err) {
        reject(err);
        return toast.error("Something went wrong");
      }
    }),
  baseURL,
};

export default api;
