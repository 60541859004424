import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import videos from "../constants/videos";
import ReactGA from "react-ga4";

const Telegram = () => {
  useEffect(() => {
    document.title = "Telegram - Game555";
  }, []);

  return (
    <div className="tel">
      <br />
      {/* <img src={images.logo} className="logo" /> */}
      <video src={videos.telegram} loop muted autoPlay className="video" />
      <button
        className="join"
        onClick={() => {
          ReactPixel.trackCustom("Join", 1);
          ReactGA.event({
            category: "MainEngagement",
            action: "Join Telegram",
          });
          window.open("tg://join?invite=-Bp1MI2KRjI1MDY1");
        }}
      >
        <img src="https://www.crickstox.com/static/media/tel_icon.8ebd63450db1ff307dd5.png" />
        Join Telegram Channel
      </button>
    </div>
  );
};

export default Telegram;
