import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { pwaInstallHandler } from "pwa-install-handler";
import ReactGA from "react-ga4";

import Clickable from "../components/clickable";

import images from "../constants/images";
import { io } from "socket.io-client";
import api from "../functions/api";
import moment from "moment";
import toast from "../functions/toast";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setWallet } from "../redux/slices";

const Home = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [nextTime, setNextTime] = useState(null);
    const [timer, setTimer] = useState(0);
    const [gameHistory, setGameHistory] = useState([]);
    const [period, setPeriod] = useState(null);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);
    const [color, setColor] = useState(null);
    const [number, setNumber] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [totalAmount, setTotalAmount] = useState(0);
    const [amount, setAmount] = useState(10);
    const [showCover, setShowCover] = useState(false);
    const [markets, setMarkets] = useState(null);
    const [market, setMarket] = useState(null);

    const menuRef = useRef();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { wallet } = useSelector((s) => s.wallet.value);
    const token = useSelector((s) => s.token.value);
    const user = useSelector((s) => s.user.value);

    const toggleSidebar = () => {
        if (menuRef.current) {
            const left = menuRef.current.style.left.split("px")[0];
            menuRef.current.style.left =
                left && parseFloat(left) >= 0 ? "-300px" : "0px";
            setShowCover(left && parseFloat(left) >= 0 ? false : true);
        }
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const getColor = (num) => {
        if ([1, 3, 7, 9].includes(num)) {
            return { backgroundColor: "#2ECC71" };
        } else if ([2, 4, 6, 8].includes(num)) {
            return { backgroundColor: "#E74C3C" };
        } else if ([5].includes(num)) {
            return {
                backgroundImage:
                    "linear-gradient(224.62deg, #D96BFF 50%, #2ECC71 50%)",
            };
        } else if (num == 10) {
            return { backgroundColor: "#D96BFF" };
        } else {
            return {
                backgroundImage:
                    "linear-gradient(224.62deg, #D96BFF 50%, #E75A3C 50%)",
            };
        }
    };

    const getGameHistory = async (p) => {
        try {
            const data = await api.get(
                "/game-history/" + market._id + "?page=" + (p || 1)
            );
            setGameHistory(p ? [...gameHistory, ...data] : data);
            setIsLastPage(data.length < 10);
        } catch (err) {
            console.log("🚀 ~ getGameHistory ~ err:", err);
        }
    };

    const getMyHistory = async (p) => {
        try {
            const data = await api.get(
                "/my-history/" + market._id + "?page=" + (p || 1)
            );
            setGameHistory(p ? [...data, ...gameHistory] : data);
            setIsLastPage(data.length < 10);
        } catch (err) {
            console.log("🚀 ~ getGameHistory ~ err:", err);
        }
    };

    const getTime = async () => {
        try {
            const data = await api.get("/time/" + market._id);
            setNextTime(new Date(data.nextTime));
            setPeriod(data.period);
        } catch (err) {
            console.log("🚀 ~ getTime ~ err:", err);
        }
    };

    const addBet = async () => {
        if (!token) return navigate("/login");

        try {
            const data = {
                market: market._id,
                amount,
                quantity,
                finalAmount: totalAmount,
                number,
                color,
            };

            await api.post("/add-bet", data);

            ReactGA.event({
                category: "MainEngagement",
                action: "Stake",
                value: amount,
            });

            toast.success("Bet placed successfully");

            getWallet();

            tabIndex == 1 && getMyHistory();
        } catch (err) {
            console.log("🚀 ~ addBet ~ err:", err);
        }
        setShowPopup(false);
    };

    const capitalizeFirstLetter = (str) => {
        str = str ? str.toString() : "";
        if (str.length === 0) return str;

        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const secondsToMMSS = (seconds) => {
        var minutes = Math.floor(seconds / 60);
        var remainingSeconds = seconds % 60;

        // Add leading zero if necessary
        var minutesString = minutes < 10 ? "0" + minutes : minutes;
        var secondsString =
            remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

        return minutesString + ":" + secondsString;
    };

    const getWallet = async () => {
        if (!token) return;
        try {
            const data = await api.get("/wallet");
            dispatch(setWallet(data));
        } catch (err) {
            console.log("🚀 ~ getWal ~ err:", err);
        }
    };

    const getMarkets = async () => {
        try {
            const data = await api.get("/markets");
            setMarkets(data);
            setMarket(data[0]);
        } catch (err) {
            console.log("🚀 ~ file: home.js:156 ~ getMarkets ~ err:", err);
        }
    };

    const maskNumber = (number, visibleDigits = 3, maskChar = "*") => {
        let numStr = number.toString();
        let visibleFromStart = Math.min(visibleDigits, numStr.length);
        let visibleFromEnd = Math.min(
            visibleDigits,
            numStr.length - visibleFromStart
        );
        let maskedStr =
            numStr.substring(0, visibleFromStart) +
            maskChar.repeat(numStr.length - visibleFromStart - visibleFromEnd) +
            numStr.substring(numStr.length - visibleFromEnd);

        return maskedStr;
    };

    useEffect(() => {
        if (market) {
            getTime();
            const socket = io(api.baseURL);
            const path = "result-" + market._id;
            socket.on(path, (data) => {
                setNextTime(new Date(data.nextTime));
                setPeriod(data.period);
                // setGameHistory([]);
                tabIndex == 0
                    ? getGameHistory()
                    : token
                    ? getMyHistory()
                    : navigate("/login");
                getWallet();
            });
            return () => socket.off(path);
        }
    }, [tabIndex, market]);

    useEffect(() => {
        if (nextTime) {
            const i = setInterval(() => {
                const utcFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
                const utcDate = moment.utc(new Date(nextTime), utcFormat);
                const istDate = utcDate.local();
                const duration = moment.duration(istDate.diff(moment()));
                const timer = Math.floor(duration.asSeconds());

                setTimer(timer <= 0 ? 0 : timer);
            }, 1000);
            return () => clearInterval(i);
        }
    }, [nextTime]);

    useEffect(() => {
        if (page > 1) {
            tabIndex == 0
                ? getGameHistory(page)
                : !token
                ? navigate("/login")
                : getMyHistory(page);
        }
    }, [page, tabIndex]);

    useEffect(() => {
        setTotalAmount(amount * quantity);
    }, [quantity, amount]);

    useEffect(() => {
        if (market) {
            setGameHistory([]);
            tabIndex == 0
                ? getGameHistory()
                : !token
                ? navigate("/login")
                : getMyHistory();
        }
    }, [tabIndex, market]);

    useEffect(() => {
        getMarkets();
    }, []);

    return (
        <div className="Home">
            {markets && market && (
                <>
                    {showCover && <div className="cover" />}
                    <div className="home-logo">
                        <div className="menu" ref={menuRef}>
                            <div className="user-pro">
                                <div className="user">
                                    <div className="user-img">
                                        <img
                                            src={images.user}
                                            className="user-icon"
                                        />
                                    </div>
                                    {token ? (
                                        <div className="user-name">
                                            <div className="name">
                                                USER {1545 + user._id}
                                            </div>
                                            <div className="user-mo">
                                                MO NO - {maskNumber(user.mobNo)}
                                            </div>
                                        </div>
                                    ) : (
                                        <Clickable
                                            onClick={() => navigate("/login")}
                                            className="login"
                                        >
                                            <div className="logout-text">
                                                Login
                                            </div>
                                        </Clickable>
                                    )}
                                </div>
                                <Clickable
                                    className="menu-close"
                                    onClick={toggleSidebar}
                                >
                                    <img
                                        src={images.cancel}
                                        className="cancel-icon"
                                    />
                                </Clickable>
                            </div>
                            <Link to={"/game"}>
                                <div className="game">
                                    <img
                                        src={images.game}
                                        className="game-icon"
                                    />
                                    <div className="menu-text">Game</div>
                                </div>
                            </Link>
                            <Link to={"/wallet-history"}>
                                <div className="game">
                                    <img
                                        src={images.history}
                                        className="game-icon"
                                    />
                                    <div className="menu-text">
                                        Transaction History
                                    </div>
                                </div>
                            </Link>

                            <Link to={"/add-amount"}>
                                <div className="game">
                                    <img
                                        src={images.addFund}
                                        className="game-icon"
                                    />
                                    <div className="menu-text">Add Fund</div>
                                </div>
                            </Link>

                            <Link to={"/withdraw"}>
                                <div className="game">
                                    <img
                                        src={images.withdraw}
                                        className="game-icon"
                                    />
                                    <div className="menu-text">Withdraw</div>
                                </div>
                            </Link>

                            <Link to={"/refer"}>
                                <div className="game">
                                    <img
                                        src={images.refer}
                                        className="refer-icon"
                                    />
                                    <div className="menu-text">
                                        Refer & Earn
                                    </div>
                                </div>
                            </Link>
                            <a href="https://t.me/Crickstox" target="_blnk">
                                <div className="game">
                                    <img
                                        src={images.support}
                                        className="game-icon"
                                    />
                                    <div className="menu-text">
                                        Support 24/7
                                    </div>
                                </div>
                            </a>
                            <a href="https://t.me/Aviatorsignal" target="_blnk">
                                <div className="game">
                                    <img
                                        src={images.channel}
                                        className="game-icon"
                                    />
                                    <div className="menu-text">
                                        Prediction Channel
                                    </div>
                                </div>
                            </a>

                            {pwaInstallHandler.canInstall() && (
                                <Clickable
                                    onClick={() => pwaInstallHandler.install()}
                                >
                                    <div className="game">
                                        <img
                                            src={images.install}
                                            className="game-icon"
                                        />
                                        <div className="menu-text">
                                            Install App
                                        </div>
                                    </div>
                                </Clickable>
                            )}
                            {token && (
                                <Clickable
                                    className="logout-btn"
                                    onClick={() => dispatch(setToken(null))}
                                >
                                    <div className="logout-text">Logout</div>
                                </Clickable>
                            )}
                        </div>

                        <div style={{ width: 70 }}>
                            <Clickable
                                onClick={() => {
                                    toggleSidebar(false);
                                }}
                            >
                                <img src={images.menu} className="menu-icon" />
                            </Clickable>
                        </div>
                        <div>
                            <img src={images.logo} className="logo" />
                        </div>
                        <div>
                            <Link to={"/add-amount"} className="user-wallet">
                                <img
                                    src={images.wallet}
                                    className="wallet-icon"
                                />
                                <div className="wallet-amount">
                                    ₹ {wallet.toFixed(2)}
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="container">
                        <div className="markets">
                            {markets.map((item) => (
                                <Clickable
                                    className={
                                        "market " +
                                        (market._id === item._id && "active")
                                    }
                                    onClick={() => setMarket(item)}
                                >
                                    {item.name}
                                </Clickable>
                            ))}
                        </div>
                        <div className="user-num">
                            <div className="period-num">
                                <div className="period">
                                    <img
                                        src={images.trophy}
                                        className="trophy-icon"
                                    />
                                    <div className="text">PERIOD NUMBER</div>
                                </div>
                                <div className="number">{period}</div>
                            </div>
                            <div className="period-num">
                                <div className="time">Timer</div>
                                <div className="time-num">
                                    {secondsToMMSS(timer)}
                                </div>
                            </div>
                        </div>

                        <div className="color-bat">
                            {timer <= market.wait && (
                                <div className="cover-timer">
                                    {timer < 10 ? "0" + timer : timer}
                                </div>
                            )}
                            <div className="three-color">
                                <div className="colors">
                                    <Clickable
                                        className="color-green"
                                        disabled={timer <= market.wait}
                                        onClick={() => {
                                            setNumber(null);
                                            setColor("green");
                                            togglePopup(false);
                                        }}
                                    >
                                        Green
                                    </Clickable>
                                    <Clickable
                                        className="color-violet"
                                        disabled={timer <= market.wait}
                                        onClick={() => {
                                            setNumber(null);
                                            setColor("violet");
                                            togglePopup(false);
                                        }}
                                    >
                                        Violet
                                    </Clickable>
                                    <Clickable
                                        className="color-red"
                                        disabled={timer <= market.wait}
                                        onClick={() => {
                                            setNumber(null);
                                            setColor("red");
                                            togglePopup(false);
                                        }}
                                    >
                                        Red
                                    </Clickable>
                                </div>
                            </div>

                            <div className="colors-number">
                                <div className="box">
                                    {Array(10)
                                        .fill()
                                        .map((_, item) => (
                                            <Clickable
                                                className="color-box"
                                                style={getColor(item)}
                                                disabled={timer <= market.wait}
                                                onClick={() => {
                                                    setColor(null);
                                                    setNumber(item);
                                                    togglePopup(false);
                                                }}
                                            >
                                                <div className="color-num">
                                                    {item}
                                                </div>
                                            </Clickable>
                                        ))}
                                </div>
                            </div>
                        </div>

                        <div className="tab-bar">
                            <div
                                className={
                                    tabIndex === 0 ? "indexActive" : "tabName"
                                }
                                onClick={() => {
                                    setGameHistory([]);
                                    setTabIndex(0);
                                }}
                            >
                                Game History
                            </div>
                            <div
                                className={
                                    tabIndex === 1 ? "indexActive" : "tabName"
                                }
                                onClick={() => {
                                    setGameHistory([]);
                                    setTabIndex(1);
                                }}
                            >
                                My History
                            </div>
                        </div>

                        {tabIndex === 0 ? (
                            <div className="game-history">
                                <div className="title">
                                    <div className="result-box">PERIOD</div>
                                    <div className="result-box">NUMBER</div>
                                    <div className="result-box">COLOUR</div>
                                </div>
                                <div className="result-user">
                                    {gameHistory.map((item, index) => (
                                        <div className="result" key={index}>
                                            <div className="result-id">
                                                {item.period}
                                            </div>
                                            <div className="result-num">
                                                {item.number}
                                            </div>
                                            <div
                                                style={{
                                                    width: "33.33%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <div
                                                    className="result-color"
                                                    style={getColor(
                                                        item.number
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <>
                                {gameHistory.map((item) => (
                                    <>
                                        <div className="win-user">
                                            <div className="user-tokan">
                                                {item.period}
                                            </div>
                                            <div
                                                className={
                                                    item.status == 0
                                                        ? "pendind-ruppes"
                                                        : item.status == 1
                                                        ? "win-ruppes"
                                                        : "loss-ruppes"
                                                }
                                            >
                                                {item.status == 0
                                                    ? "Pending..."
                                                    : item.status == 1
                                                    ? "Win"
                                                    : "Loss"}{" "}
                                                {item.status == 1
                                                    ? "+"
                                                    : item.status == 2
                                                    ? "-"
                                                    : ""}{" "}
                                                {item.winningAmount &&
                                                item.winningAmount > 0
                                                    ? item.winningAmount
                                                    : item.status == 2
                                                    ? item.finalAmount
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="user-history">
                                            <div className="user-bet">
                                                BET DETAILS
                                            </div>

                                            <div className="user">
                                                <div className="history-text">
                                                    PERIOD
                                                </div>
                                                <div className="history-text">
                                                    {item.period}
                                                </div>
                                            </div>
                                            {/* 
                                    <div className="user">
                                        <div className="history-text">
                                            CONTRACT MONEY
                                        </div>
                                        <div className="history-text">
                                            {item.amount}
                                        </div>
                                    </div> */}

                                            {/* <div className="user">
                                        <div className="history-text">
                                            CONTRACT COUNT
                                        </div>
                                        <div className="history-text">
                                            {item.quantity}
                                        </div>
                                    </div> */}

                                            {/* <div className="user">
                                        <div className="history-text">
                                            DELIVERY
                                        </div>
                                        <div className="history-text">9.80</div>
                                    </div> */}

                                            {/* <div className="user">
                                        <div className="history-text">FEE</div>
                                        <div className="history-text">0.0</div>
                                    </div> */}

                                            {/* <div className="user">
                                        <div className="history-text">
                                            OPEN PRICE
                                        </div>
                                        <div className="history-text">
                                            39361
                                        </div>
                                    </div> */}

                                            <div className="user">
                                                <div className="history-text">
                                                    SELECT
                                                </div>

                                                {item.color ? (
                                                    <div
                                                        className="result-color"
                                                        style={getColor(
                                                            item.color == "red"
                                                                ? 2
                                                                : item.color ==
                                                                  "green"
                                                                ? 1
                                                                : item.color ==
                                                                  "red + violet"
                                                                ? 0
                                                                : item.color ==
                                                                  "violet"
                                                                ? 10
                                                                : 5
                                                        )}
                                                    />
                                                ) : (
                                                    <div>{item.number}</div>
                                                )}
                                            </div>

                                            <div className="user">
                                                <div className="history-text">
                                                    Total AMOUNT
                                                </div>
                                                <div className="history-text">
                                                    {item.finalAmount}
                                                </div>
                                            </div>

                                            {item.status > 0 && (
                                                <div className="user">
                                                    <div className="history-text">
                                                        RESULT
                                                    </div>
                                                    <div className="select-num">
                                                        <div className="select-color">
                                                            {item.result}
                                                        </div>
                                                        <div
                                                            className="result-color"
                                                            style={getColor(
                                                                item.result
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            <div className="user">
                                                <div className="history-text">
                                                    STATUS
                                                </div>
                                                <div className="history-text">
                                                    {item.status == 0
                                                        ? "PENDING"
                                                        : item.status == 1
                                                        ? "SUCCESS"
                                                        : "FAIL"}
                                                </div>
                                            </div>

                                            <div className="user">
                                                <div className="history-text">
                                                    CREATE TIME
                                                </div>
                                                <div className="history-text">
                                                    {moment(
                                                        item.createdAt
                                                    ).format(
                                                        "DD/MM/YY hh:mm:ss a"
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                                {/* <div className="win-user">
                            <div className="user-tokan">20231118259</div>
                            <div className="win-ruppes">WIN + 350</div>
                        </div>
                        <div className="win-user">
                            <div className="user-tokan">20231118259</div>
                            <div className="loss-ruppes">Loss - 350</div>
                        </div>
                        <div className="user-history">
                            <div className="user">
                                <div className="tokan">20231118259</div>
                                <div className="ruppes">WIN + 350</div>
                            </div>
                            <div className="user-bet">BET DETAILS</div>

                            <div className="user">
                                <div className="history-text">PERIOD</div>
                                <div className="history-text">20231118259</div>
                            </div>

                            <div className="user">
                                <div className="history-text">
                                    CONTRACT MONEY
                                </div>
                                <div className="history-text">10</div>
                            </div>

                            <div className="user">
                                <div className="history-text">
                                    CONTRACT COUNT
                                </div>
                                <div className="history-text">1</div>
                            </div>

                            <div className="user">
                                <div className="history-text">DELIVERY</div>
                                <div className="history-text">9.80</div>
                            </div>

                            <div className="user">
                                <div className="history-text">FEE</div>
                                <div className="history-text">0.0</div>
                            </div>

                            <div className="user">
                                <div className="history-text">OPEN PRICE</div>
                                <div className="history-text">39361</div>
                            </div>

                            <div className="user">
                                <div className="history-text">RESULT</div>
                                <div className="select-num">
                                    <div className="select-color">1</div>
                                    <div className="result-color"></div>
                                </div>
                            </div>

                            <div className="user">
                                <div className="history-text">SELECT</div>
                                <div className="result-color"></div>
                            </div>

                            <div className="user">
                                <div className="history-text">STATUS</div>
                                <div className="history-text">SUCCESS</div>
                            </div>

                            <div className="user">
                                <div className="history-text">AMOUNT</div>
                                <div className="history-text">19.60</div>
                            </div>

                            <div className="user">
                                <div className="history-text">CREATE TIME</div>
                                <div className="history-text">
                                    20-11-2023 12:31
                                </div>
                            </div>
                        </div> */}
                            </>
                        )}

                        {!isLastPage && (
                            <div className="load">
                                <Clickable
                                    className="load-btn"
                                    onClick={() => setPage(page + 1)}
                                >
                                    Load More
                                </Clickable>
                            </div>
                        )}
                    </div>

                    {showPopup && (
                        <div className="select-amount">
                            <div className="popup">
                                <div className="select-num-color">
                                    Selected:{" "}
                                    {capitalizeFirstLetter(color) || number}
                                </div>

                                <div className="balance">
                                    <div className="balance-text">Balance</div>
                                    <div className="balance-add">
                                        <Clickable
                                            className={
                                                "balance-num " +
                                                (amount === 10 ? "active" : "")
                                            }
                                            onClick={() => setAmount(10)}
                                        >
                                            10
                                        </Clickable>
                                        <Clickable
                                            className={
                                                "balance-num " +
                                                (amount === 100 ? "active" : "")
                                            }
                                            onClick={() => setAmount(100)}
                                        >
                                            100
                                        </Clickable>
                                        <Clickable
                                            className={
                                                "balance-num " +
                                                (amount === 1000
                                                    ? "active"
                                                    : "")
                                            }
                                            onClick={() => setAmount(1000)}
                                        >
                                            1000
                                        </Clickable>
                                    </div>
                                </div>

                                <div className="balance">
                                    <div className="balance-text">Quantity</div>
                                    <div className="balance-add">
                                        <Clickable
                                            className="minus"
                                            onClick={() =>
                                                setQuantity(quantity - 1)
                                            }
                                            disabled={quantity <= 1}
                                        >
                                            -
                                        </Clickable>
                                        <div className="input-num">
                                            {quantity}
                                        </div>
                                        <Clickable
                                            className="minus"
                                            onClick={() =>
                                                setQuantity(quantity + 1)
                                            }
                                            disabled={quantity >= 10}
                                        >
                                            +
                                        </Clickable>
                                    </div>
                                </div>

                                <div className="buttons">
                                    <Clickable
                                        className="cancel"
                                        onClick={togglePopup}
                                    >
                                        Cancel
                                    </Clickable>
                                    <Clickable
                                        className="total"
                                        onClick={addBet}
                                    >
                                        Total Amount: {totalAmount}
                                    </Clickable>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Home;
