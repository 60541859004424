import { useEffect, useState } from "react";
import api from "../functions/api";
import toast from "../functions/toast";
import { io } from "socket.io-client";
import moment from "moment";
import { useParams } from "react-router-dom";

const SastaAdmin = () => {
  const [data, setData] = useState(null);
  const [nextTime, setNextTime] = useState(null);
  const [timer, setTimer] = useState(0);
  const [period, setPeriod] = useState(null);

  const { market } = useParams();

  const getColor = (num) => {
    if ([1, 3, 7, 9].includes(num)) {
      return { backgroundColor: "#2ECC71" };
    } else if ([2, 4, 6, 8].includes(num)) {
      return { backgroundColor: "#E74C3C" };
    } else if ([5].includes(num)) {
      return {
        backgroundImage: "linear-gradient(224.62deg, #D96BFF 50%, #2ECC71 50%)",
      };
    } else if (num == 10) {
      return { backgroundColor: "#D96BFF" };
    } else {
      return {
        backgroundImage: "linear-gradient(224.62deg, #D96BFF 50%, #E75A3C 50%)",
      };
    }
  };

  const secondsToMMSS = (seconds) => {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;

    // Add leading zero if necessary
    var minutesString = minutes < 10 ? "0" + minutes : minutes;
    var secondsString =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

    return minutesString + ":" + secondsString;
  };

  const getData = async () => {
    try {
      const data = await api.get("/bet-amounts/" + market);
      setData(data);
    } catch (err) {
      console.log("🚀 ~ getData ~ err:", err);
    }
  };

  const addResult = async (result) => {
    try {
      await api.get("/add-result/" + result + `?market=${market}`);
      toast.success("Result declared");
    } catch (err) {
      console.log("🚀 ~ addResult ~ err:", err);
    }
  };

  const getTime = async () => {
    try {
      const data = await api.get("/time/" + market);
      setNextTime(new Date(data.nextTime));
      setPeriod(data.period);
    } catch (err) {
      console.log("🚀 ~ getTime ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
    getTime();
    const int = setInterval(() => {
      getData();
    }, 2000);
    const socket = io(api.baseURL);
    socket.on("result-" + market, (data) => {
      setNextTime(new Date(data.nextTime));
      setPeriod(data.period);
    });
    return () => {
      clearInterval(int);
      socket.off("result-" + market);
    };
  }, []);

  useEffect(() => {
    if (nextTime) {
      const i = setInterval(() => {
        const utcFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
        const utcDate = moment.utc(new Date(nextTime), utcFormat);
        const istDate = utcDate.local();
        const duration = moment.duration(istDate.diff(moment()));
        const timer = Math.floor(duration.asSeconds());

        setTimer(timer <= 0 ? 0 : timer);
      }, 1000);
      return () => clearInterval(i);
    }
  }, [nextTime]);

  return (
    <>
      {data && (
        <div className="s-admin">
          <br />
          <div className="head">
            <span>{period}</span>
            <span className="timer">{secondsToMMSS(timer)}</span>
          </div>
          <br />
          <div className="colors">
            <div className="color" style={getColor(2)}>
              <span>Red</span>
              {data.colors.red}
            </div>
            <div className="color" style={getColor(10)}>
              <span>Violet</span>
              {data.colors.violet}
            </div>
            <div className="color" style={getColor(1)}>
              <span>Green</span>
              {data.colors.green}
            </div>
          </div>
          <br />
          <div className="nums colors">
            {data.numbers.map((item, index) => (
              <div
                className="color num"
                style={getColor(index)}
                onClick={() => addResult(index)}
              >
                <span>{index}</span>
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SastaAdmin;
